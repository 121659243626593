import VSpacing from 'bloko/blocks/vSpacing';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';
import { BannerPlace } from 'src/models/banners';

const RightBanners: React.FC = () => {
    const rightBanners = useSelector(({ banners }) => banners[BannerPlace.RIGHT_COLUMN]);

    return (
        <>
            {rightBanners?.map((banner) => (
                <div key={banner.cId} className="banner-place-fat-wrapper">
                    <AdsExternalBanner {...banner} />
                    <VSpacing base={6} />
                </div>
            ))}
        </>
    );
};

export default RightBanners;
