import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import Link from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { UserType } from 'src/models/userType';

import styles from './styles.less';

const TrlKeys = {
    search: 'employers.company.search',
    new: 'title.newEmployer',
};

const TopLinks: TranslatedComponent = ({ trls }) => {
    const defaultCountryCompanySearchId = useSelectorNonNullable((state) => state.defaultCountryCompanySearchId);
    const userType = useSelectorNonNullable((state) => state.userType);

    return (
        <div className={styles.linkWrapper}>
            <Link href={`/employers_list?areaId=${defaultCountryCompanySearchId}`} disableVisited>
                {trls[TrlKeys.search]}
            </Link>
            {userType === UserType.Anonymous && (
                <Button Element="a" href="/auth/employer" kind={ButtonKind.Success} scale={ButtonScale.Small}>
                    {trls[TrlKeys.new]}
                </Button>
            )}
        </div>
    );
};

export default translation(TopLinks);
