import { useState, useMemo } from 'react';
import classname from 'classnames';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';
import { EmployersCompanyIndustry } from 'src/models/employersCompany';
import useIndustries from 'src/pages/EmployersCompany/hooks/industries';

import Breadcrumbs from 'src/pages/EmployersCompany/components/Industries/Breadcrumbs';
import ExpandLink from 'src/pages/EmployersCompany/components/Industries/ExpandLink';
import Industry from 'src/pages/EmployersCompany/components/Industries/Industry';

import styles from './styles.less';

const MAX_SUBINDUSTRIES = 6;

const sortIndustries = (industries: EmployersCompanyIndustry[]) =>
    industries.sort((a, b) => a.title.localeCompare(b.title));

const Industries: React.FC = () => {
    const industries = useSelectorNonNullable((state) => state.employersCompany.industries);
    const { industry, subIndustry } = useIndustries();

    const [isExpanded, setIsExpanded] = useState(false);

    const filteredIndustries = useMemo(() => {
        if (subIndustry) {
            return [];
        }

        if (industry) {
            return sortIndustries(industries.filter((item) => item.parentId === industry.id));
        }

        return sortIndustries(industries.filter((item) => !item.parentId));
    }, [industry, subIndustry, industries]);

    const showBreadcrumbs = Boolean(industry);
    const showExpandLink = industry && !subIndustry && !isExpanded && filteredIndustries.length > MAX_SUBINDUSTRIES;

    const slicedIndustries = useMemo(() => {
        if (showExpandLink) {
            return filteredIndustries.slice(0, MAX_SUBINDUSTRIES);
        }

        return filteredIndustries;
    }, [showExpandLink, filteredIndustries]);

    return (
        <div
            className={classname(styles.industries, {
                [styles.subIndustries]: industry && !subIndustry,
            })}
        >
            {showBreadcrumbs && <Breadcrumbs industry={industry} subIndustry={subIndustry} />}
            {Boolean(slicedIndustries.length) && (
                <>
                    {showBreadcrumbs && <VSpacing base={5} />}
                    <div className={styles.list}>
                        {slicedIndustries.map((industry) => (
                            <Industry key={industry.id} industry={industry} />
                        ))}
                    </div>
                </>
            )}
            {showExpandLink && (
                <ExpandLink value={filteredIndustries.length - MAX_SUBINDUSTRIES} setIsExpanded={setIsExpanded} />
            )}
        </div>
    );
};

export default translation(Industries);
